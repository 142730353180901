<template>
  <b-card>
    <div class="my-2">
        <b-row class="mx-2">
            <b-col cols="6" md="4" class="my-1">
              <h4 class="font-weight-bolder">Proyecto</h4>
              <h6 class="font-weight-normal">{{property.project.name}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Identificador</h4>
                <h6 class="font-weight-normal">{{property.identifier}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Modelo</h4>
                <h6 class="font-weight-normal">{{numberPrice(property.model)}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Precio</h4>
                <h6 class="font-weight-normal">{{property.price}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Piso</h4>
                <h6 class="font-weight-normal">{{property.floor}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Dormitorios</h4>
                <h6 class="font-weight-normal">{{property.bedrooms}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Baños</h4>
                <h6 class="font-weight-normal">{{property.bathrooms}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Torre</h4>
                <h6 class="font-weight-normal">{{property.tower != '' ? property.tower : "Sin datos"}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Área total</h4>
                <h6 class="font-weight-normal">{{property.total_area > 0 ? property.total_area : 0}} mts<sup>2</sup></h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Área municipal</h4>
                <h6 class="font-weight-normal">{{property.municipal_area > 0 ? property.municipal_area : 0}} mts<sup>2</sup></h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Área construcción</h4>
                <h6 class="font-weight-normal">{{property.built_area > 0 ? property.built_area : 0}} mts<sup>2</sup></h6>
            </b-col>
        </b-row>
    </div>
  </b-card>
</template>

<script>
  export default {
    name: 'QuotationDetailProperty',
    props: ['property'],
    data() {
      return {
      }
    },
    methods:{
      numberPrice(x) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },
    }
  }
</script>