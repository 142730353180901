<template>
  <b-card>
    <div class="my-2">
        <b-row class="mx-2">
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Identificador</h4>
                <h6 class="font-weight-normal">{{warehouse.identifier}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Precio</h4>
                <h6 class="font-weight-normal">{{numberPrice(warehouse.price)}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Piso</h4>
                <h6 class="font-weight-normal">{{warehouse.floor}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Área total</h4>
                <h6 class="font-weight-normal">{{warehouse.total_area > 0 ? warehouse.total_area : 0}} mts<sup>2</sup>}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Área municipal</h4>
                <h6 class="font-weight-normal">{{warehouse.municipal_area > 0 ? warehouse.municipal_area : 0}} mts<sup>2</sup>}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Área construcción</h4>
                <h6 class="font-weight-normal">{{warehouse.built_area > 0 ? warehouse.built_area : 0}} mts<sup>2</sup>}}</h6>
            </b-col>
        </b-row>
    </div>
  </b-card>
</template>

<script>
    export default{
        name: 'BookingOrderDetailProperty',
        props: ['warehouse'],
        data() {
            return {
            }
        },
        methods:{
            numberPrice(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
        }
    }
</script>