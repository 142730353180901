<template>
  <b-card>
    <div class="my-2">
        <b-row class="mx-2">
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Nombre</h4>
                <h6 class="font-weight-normal">{{general.customer.name}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Apellido</h4>
                <h6 class="font-weight-normal">{{general.customer.last_name}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Correo</h4>
                <h6 class="font-weight-normal">{{general.customer.email}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">R.U.N.</h4>
                <h6 class="font-weight-normal">{{general.customer.rut}}-{{general.customer.rut_dv}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Teléfono</h4>
                <h6 class="font-weight-normal">{{general.customer.phone}}</h6>
            </b-col>
            <b-col cols="6" md="4" class="my-1" v-if="general.customer.subsidio != '' && general.customer.subsidio != null">
                <h4 class="font-weight-bolder">Subsidio</h4>
                <h6 class="font-weight-normal">{{general.customer.subsidio}}</h6>
            </b-col>
        </b-row>
    </div>
  </b-card>
</template>

<script>
  export default {
    name: 'QuotationDetailGeneral',
    props: ['general'],
    data() {
      return {
      }
    },
  }
</script>