<template>
  <div id="quoatations-details">
    <quotation-details-list :quotation="quotation"></quotation-details-list>
  </div>
</template>

<script>
  import QuotationDetailsList from './components/QuotationDetailsList'

  export default{
    name: 'QuotationsDetails',
    components: {QuotationDetailsList},
    props: ['quotation'],
    data() {
      return {
      }
    },
  }
</script>