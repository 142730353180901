<template>
  <div>
    <b-tabs pills vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      nav-class="nav-left"
      nav-wrapper-class="col-md-3 col-12">
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"/>
          <span class="font-weight-bold">General</span>
        </template>
        <quotation-detail-general :general="quotation"></quotation-detail-general>
      </b-tab>
      <b-tab v-if="quotation.propertyquotation[0].property != null">
        <template #title>
          <feather-icon
            icon="HomeIcon"
            size="18"
            class="mr-50"/>
          <span class="font-weight-bold">Propiedad</span>
        </template>
        <quotation-detail-property :property="quotation.propertyquotation[0].property"></quotation-detail-property>
      </b-tab>
      <b-tab v-if="quotation.propertyquotation[0].warehouse != null">
        <template #title>
          <feather-icon
            icon="BoxIcon"
            size="18"
            class="mr-50"/>
          <span class="font-weight-bold">Bodega</span>
        </template>
        <quotation-detail-warehouse :warehouse="quotation.propertyquotation[0].warehouse"></quotation-detail-warehouse>
      </b-tab>
      <b-tab v-if="quotation.propertyquotation[0].parkinglot != null">
        <template #title>
          <feather-icon
            icon="TruckIcon"
            size="18"
            class="mr-50"/>
          <span class="font-weight-bold">Estacionamiento</span>
        </template>
        <quotation-detail-parkinglot :parkinglot="quotation.propertyquotation[0].parkinglot"></quotation-detail-parkinglot>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import QuotationDetailGeneral from './details/QuotationDetailGeneral'
  import QuotationDetailParkinglot from './details/QuotationDetailParkinglot'
  import QuotationDetailProperty from './details/QuotationDetailProperty'
  import QuotationDetailWarehouse from './details/QuotationDetailWarehouse'

  export default{
    name: 'QuotationsDetailsList',
    props: ['quotation'],
    components:{
      QuotationDetailGeneral,
      QuotationDetailParkinglot,
      QuotationDetailProperty,
      QuotationDetailWarehouse
    },
    data() {
      return {
      }
    },
  }
</script>